import React from "react";
import { Seo } from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../components/layout";
const Thankyou = () => {
  return (
    <Layout>
      <section className="py-[100px] flex min-h-[100vh] items-center justify-center bg-[#121212] banner-bg-2 bg-[url('../images/guides.webp')] bg-center flex-col text-[#fff] gap-[39px]">
        <StaticImage
          src="../images/clap.svg"
          alt="thankyou"
          placeholder="none"
        />
        <div className="text-center">
          <h1 className="text-[48px] leading-[50px] tracking-[.08em] font-medium text-[#fff] font-heading mb-[20px]">
            We look forward to meeting you.
          </h1>
          <p className="text-[16px] leading-[25px] tracking-[.03em] font-normal">
            Meeting details have been shared on you email id.
          </p>
        </div>
        <Link to="/" className="btn btn-primary small block mx-auto">
          Go To Home Page
        </Link>
      </section>
    </Layout>
  );
};
export default Thankyou;

export const Head = () => (
  <Seo
    title="Thank You- Octet Design Studio"
    description="We appreciate your effort for getting in touch with us for your UI UX queries. Looking forward to discuss about your project's challenges and solutions."
  />
);
