// seo.js
import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";
// import { graphql, useStaticQuery } from "gatsby";
// import { getImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

export const Seo = ({ title, description, image, date, modified, children, }) => {
  const { title: defaultTitle, description: defaultDescription, image: defaultImage, date: defaultDate, modified: defaultModified } = useSiteMetadata();

  
  // Fetch the optimized image data for the default image
  // const { placeholderImage, dynamicImageOctet, dynamicImageVideo } = useStaticQuery(
  //   graphql`
  //   query {
  //     placeholderImage: file(relativePath: { eq: "octet-logo.webp" }) {
  //       childImageSharp {
  //         gatsbyImageData(layout: FIXED)
  //       }
  //     }
  //     dynamicImageOctet: file(relativePath: { eq: "octet-logo.webp" }) {
  //       childImageSharp {
  //         gatsbyImageData(layout: FIXED)
  //       }
  //     }
  //     dynamicImageVideo: file(relativePath: { eq: "octet-logo-white.webp" }) {
  //       childImageSharp {
  //         gatsbyImageData(layout: FIXED)
  //       }
  //     }
  //   }
  //   `
  // );

  // let ogImage;

  // if (image === "octet-logo.webp") {
  //   ogImage = getImage(dynamicImageOctet).images.fallback.src;
  // } else if (image === "video.webp") {
  //   ogImage = getImage(dynamicImageVideo).images.fallback.src;
  // } else if (image === undefined) {
  //   ogImage = getImage(placeholderImage).images.fallback.src;
  // } else {
  //   ogImage = image;
  // }

  // console.log(image, 'image', ogImage)

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: image || defaultImage,
    date: date || defaultDate,
    modified: modified || defaultModified,
  };
  const location = useLocation();
  // console.log(seo)
  return (
    <>
      {/* <html lang="en" /> */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={`https://octet.design${location.pathname}`} />
      <meta property="og:site_name" content="Octet Design Studio" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta property="article:published_time" content={seo.date} />
      <meta itemProp="datePublished" content={seo.date} />
      <meta property="article:modified_time" content={seo.modified} />
      <meta itemProp="dateModified" content={seo.modified} />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      {children}
    </>
  );
};
